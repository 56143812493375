<template>
  <v-dialog persistent max-width="600" v-model="dialog" >
    <v-sheet class="pa-4" rounded="lg">
      <div class="d-flex align-center">
        <div class="poppins fw600 poppins--text primary--text">
          Contacts
        </div>
        <v-spacer/>
        <v-btn icon small @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-row no-gutters class="my-2">
        <v-col cols="12" lg="8" md="8" sm="12">
          <!-- <v-autocomplete
            v-model="select"
            dense
            class="roboto f14 fw600 secondary-1--text"
            prepend-inner-icon="mdi-magnify"
            :items="filter == 'ALL' ? contacts : contacts.filter(contact => contact.role == filter)"
            :search-input.sync="search"
            item-text="first_name"
            item-value="id"
            return-object
            cache-items
            flat
            hide-details
            solo
            label="Search"
          /> -->
          <v-text-field 
            filled 
            class="noline general-custom-field roboto f14 secondary-1--text fw500" 
            dense
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            hide-details
            v-model="search"
          />
        </v-col>
        <v-col cols="4" class="hidden-sm-and-down">
          <div class="d-flex align-center justify-end">
            <FormLabel class="mr-1" :label="'Filter by: '"/>
            <v-select 
              filled 
              class="col-7 noline general-custom-field roboto f14 secondary-1--text fw500" 
              dense
              hide-details
              :items="items"
              v-model="filter"
            />
          </div>
        </v-col>
      </v-row>
      <v-sheet height="300" class="overflow-y-auto">
        <v-item-group multiple v-model="users">
          <v-item 
            v-for="item in contactList" 
            :key="item.id"
            :value="item.id"
            v-slot="{ active, toggle }"
            class="my-1"
          >
            <v-sheet class="my-3 d-flex align-center">
              <v-simple-checkbox :ripple="false" :value="active" color="primary" @click="toggle"/>
              <v-avatar class="" size="50">
                <v-img :src="item.image ? item.image.url : require('../../../assets/default-photo.png')"
                  v-on:error="require('../../../assets/default-photo.png')"></v-img>
              </v-avatar>
              <div class="poppins ml-3">
                <div class="f13 fw500">
                  {{ item.first_name ? item.first_name : 'User' }}
                  {{ $userFormat.middle_initial(item.middle_name) }}
                  {{ item.last_name ? item.last_name : 'Account' }}
                  {{ item.suffix ? item.suffix : '' }}
                </div>
                <FormLabel :label="item.role==='USER' ? 'LEARNER' : item.role"/>
              </div>
            </v-sheet>
          </v-item>
        </v-item-group>
      </v-sheet>
      <div class="d-flex align-center justify-end mt-2">
        <v-btn text class="secondary--text text-capitalize poppins" @click="$emit('close')">Cancel</v-btn>
        <v-btn color="primary" class=" text-capitalize poppins" @click="add" :loading="loading"><v-icon left>mdi-plus</v-icon>Add</v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialog', 'contacts', 'group_chat_uuid'],
  data: () => ({
    filter: 'ALL',
    items: ['ALL', 'USER', 'INSTRUCTOR'],
    users: [],
    search: '',
    loading: false,
    asset_path: `${process.env.VUE_APP_ASSET_PATH}`
  }),

  created() {
    this.users = []
  },
 
  methods: {
    add() {
      this.loading = true
      this.$store.dispatch('usr/addUsersGroupAction',{
        group_chat_uuid: this.group_chat_uuid,
        user_id:  this.users
      }).then(_ => {
        this.loading = false
        this.$emit('close')
      })
    }
  },

  computed: {
    contactList(){
      if (this.search == '') {
        if (this.filter == 'ALL' || this.$vuetify.breakpoint.mobile){
          return this.contacts
        } else {
          return this.contacts.filter(contact => contact.role == this.filter)
        }
      } else {
        if (this.filter == 'ALL' || this.$vuetify.breakpoint.mobile){
          return this.contacts.filter(contact => String(`${contact.first_name} ${contact.last_name}`).toLowerCase().search(this.search.toLowerCase()) !== -1)
        } else return this.contacts.filter(contact => contact.role == this.filter && String(`${contact.first_name} ${contact.last_name}`).toLowerCase().search(this.search.toLowerCase()) !== -1)
      }
      
    }
  },

  watch: {
    dialog(){
      this.search = ''
      this.users = []
    }
  }
}
</script>
