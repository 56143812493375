<template>
  <v-sheet class="d-flex align-end" v-if="type=='private'">
    <v-avatar size="30" class="mr-2 mb-3" v-if="user.id != message.from_user_id">
      <v-img 
        :src="data.receiver.image? data.receiver.image.url : require('../../../assets/default-photo.png')"
        v-on:error="require('../../../assets/default-photo.png')"></v-img>
    </v-avatar>
    <div class="">
      <v-sheet 
        class="pa-3 chat-card"
        :class="user.id == message.from_user_id?'rounded-tr-xl rounded-l-xl':'rounded-tl-xl rounded-r-xl'"
        :dark="user.id == message.from_user_id" 
        outlined 
        max-width="300" 
        :color="user.id == message.from_user_id?'primary':'secondary-5'">
        <div class="f13 poppins fw500">{{message.message_body}}</div>
      </v-sheet>
      <div class="f12 poppins secondary--text" :class="user.id == message.from_user_id?'text-right': 'text-left'">
        {{$dateFormat.chatTime(message.created_at)}}
      </div>
    </div>
    
  </v-sheet>
  <!-- <div v-else-if="message.synced_users" class="f12 poppins secondary-2-text">
    {{ message.message_body }}
  </div> -->
  <v-sheet class="d-flex align-start" v-else>
    <div v-if="message.message_body.includes('has been added to the group')" class="f12 poppins secondary-2-text">
      {{ message.message_body }}
    </div>
    <!-- <v-avatar size="30" class="mr-2" v-else-if="user.id != message.sender_id && type=='private'">
      <v-img 
        :src="`https://avatars0.githubusercontent.com/u/9064073?v=4&s=460`"
      />
    </v-avatar> -->
    <div v-else class="d-flex align-center">
      <v-avatar size="30" class="mr-2" v-if="user.id !== message.sender_id">
        <v-img 
          :src="friend.image? friend.image.url : require('../../../assets/default-photo.png')"
          v-on:error="require('../../../assets/default-photo.png')"></v-img>
      </v-avatar>

      <div class="d-flex flex-column ml-2" >
        <span v-if="user.id != message.sender_id" class="f11 poppins fw400 secondary-2--text text-left">
          {{ friend.first_name }} {{ friend.last_name }}
        </span>
        
        <v-sheet 
          class="pa-3 chat-card"
          :class="user.id == message.sender_id?'rounded-tr-xl rounded-l-xl':'rounded-tl-xl rounded-r-xl'"
          :dark="user.id == message.sender_id" 
          outlined 
          max-width="300" 
          :color="user.id == message.sender_id?'primary':'secondary-5'">
          <div class="f13 poppins fw500">{{message.message_body}}</div>
        </v-sheet>
        <div class="f12 poppins secondary--text" :class="user.id == message.sender_id?'text-right': 'text-left'">
          {{$dateFormat.chatTime(message.created_at)}}
        </div>
      </div>
    </div>
    
  </v-sheet>
  
</template>

<script>
export default {
  props: ['message', 'user', 'type', 'data', "friend"],
  data: () => ({
    asset_path: `${process.env.VUE_APP_ASSET_PATH}`,
  })
}
</script>